import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { ModuleSearch } from "../../components/ModuleSearch";
import { ModuleResultTareas } from "../../components/ModuleResultTareas";
import { Loading } from "../../components/Loading";
import { Modal } from "../../components/Modal";
import {
  METADATA_SEARCH_TAREAS,
  METADATA_RESULT_TAREAS,
  TAREAS_BY_PAGE,
} from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const BuscadorTareas = () => {
  const { permissions } = usePermissions();
  const [lists, setLists] = useState({});
  const [result, setResult] = useState({});
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [visibleModal, setVisibleModal] = useState("");
  const [currentTarea, setCurrentTarea] = useState("");
  const [listFilters, setListFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [dataFilter, setDataFilter] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    API.obtenerFiltros({ modulo: "BuscadorTareas" })
      .then((res) => isMounted && setListFilters(res))
      .catch((error) => API.DEVELOP && console.error(error))
      .finally(() => setLoading(false));

    API.obtenerListas("BuscadorTareas", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.error(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, []);

  const searchTareas = (data = formData) => {
    setLoading(true);
    setFormData(data);
    const ordenTmp = data.orden;
    data.orden = order(currentSort);
    data.paginacion = pagination(1);

    API.buscadordeTareas(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setSelectedIds([]);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => {
        setLoading(false);
        data.orden = ordenTmp;
      });
  };

  const moreTareas = () => {
    setLoading(true);
    const data = structuredClone(formData);
    data.orden = order();
    data.paginacion = pagination(page);
    API.buscadordeTareas(data)
      .then((res) => {
        setResult({
          informacionRegistros: res.informacionRegistros,
          registros: [...result.registros, ...res.registros],
        });
        setPage(page + 1);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const sort = (field) => {
    setCurrentFilter("");
    setLoading(true);
    let direction;
    if (field !== currentSort.field) direction = 1;
    else direction = currentSort.direction === 1 ? 2 : 1;
    const newSort = { field, direction };
    setCurrentSort(newSort);
    const data = structuredClone(formData);
    data.orden = order(newSort);
    data.paginacion = pagination(1);
    API.buscadordeTareas(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setSelectedIds([]);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const pagination = (page) => {
    return {
      pagina: page,
      cantidadRegistros: TAREAS_BY_PAGE,
    };
  };

  const order = (data = currentSort) => {
    return {
      campoOrden: data.field,
      direccion: data.direction,
    };
  };

  const handleChangeSelection = (e) => {
    const { id, checked } = e.target;
    const tmpSelectedIds = [...selectedIds];
    if (checked) {
      tmpSelectedIds.push(id);
      setSelectedIds(tmpSelectedIds);
    } else {
      setSelectedIds(tmpSelectedIds.filter((e) => e !== id));
    }
  };

  const selectAll = () => {
    setSelectedIds(
      result.registros
        ?.filter((e) => e.acciones.reAsignable)
        .map((e) => e.tarea.valor) || []
    );
  };

  const deselectAll = () => {
    setSelectedIds([]);
  };

  const requestAsignTarea = (idTarea) => {
    setCurrentTarea(idTarea);
    setVisibleModal("asignTarea");
  };

  const asignTarea = () => {
    const data = { tarea: currentTarea };
    setLoading(true);
    setVisibleModal("");
    API.asignarTarea(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          searchTareas(formData);
          toast.success("Tarea asignada exitosamente");
        } else
          toast.error("No se pudo asignar la tarea\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo asignar la tarea\n" + error.msg?.title);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestReasignTarea = (idTarea, auto) => {
    setCurrentTarea(idTarea);
    setVisibleModal(
      idTarea
        ? "reasignTarea"
        : auto
        ? "massiveAsignTarea"
        : "massiveReasignTarea"
    );
  };

  const reasignTarea = (user) => {
    const data = {
      tareas: currentTarea ? [currentTarea] : selectedIds,
      usuarioaasignar: user,
    };
    setLoading(true);
    setVisibleModal("");
    API.reAsignarTarea(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          searchTareas(formData);
          toast.success("Tarea(s) reasignada(s) exitosamente");
        } else
          toast.error(
            "No se pudo reasignar la(s) tarea(s)\n" + res.mensajeUsuario
          );
      })
      .catch((error) => {
        toast.error("No se pudo reasignar la(s) tarea(s)\n" + error.msg?.title);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };
  const requestReAbrirTarea = (idTarea) => {
    setCurrentTarea(idTarea);
    setVisibleModal("reabrirTarea");
  };

  const reabrirTarea = () => {
    const data = { tarea: currentTarea };
    setLoading(true);
    setVisibleModal("");
    API.reabrirTarea(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          searchTareas(formData);
          toast.success("Tarea Reabierta exitosamente");
        } else
          toast.error("No se pudo Reabrir la tarea\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo reabrir la tarea\n" + error.msg?.title);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const summary = () => {
    if (currentTarea) return null;

    const counts = {};
    const selectedRecords = result.registros
      ?.filter((r) => selectedIds.includes(r.tarea.valor))
      .map((e) => e.tarea.etiqueta);
    selectedRecords?.forEach((r) => {
      counts[r] = (counts[r] || 0) + 1;
    });
    return counts;
  };

  const requestSaveFilter = (data) => {
    data.orden = order(currentSort);
    setDataFilter(data);
    setVisibleModal("saveFilter");
  };

  const saveFilter = (name) => {
    setLoading(true);
    const data = {};
    data.modulo = "BuscadorTareas";
    data.nombre = name;
    data.filtro = dataFilter;

    API.guardarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters];
          data.id = res.idFiltro;
          tmpListFilters.push(data);
          setListFilters(tmpListFilters);
          setCurrentFilter(res.idFiltro);
          toast.success("Filtro Guardado");
        } else
          toast.error("No se pudo guardar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo guardar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
      });
  };

  const requestDeleteFilter = (data) => {
    setVisibleModal("deleteFilter");
  };

  const deleteFilter = () => {
    setLoading(true);
    const data = {};
    data.idFiltro = currentFilter;

    API.eliminarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters].filter(
            (filter) => filter.id !== currentFilter
          );
          setListFilters(tmpListFilters);
          toast.success("Filtro Eliminado");
        } else
          toast.error("No se pudo eliminar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo eliminar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
        setCurrentFilter("");
      });
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      {visibleModal === "asignTarea" && (
        <Modal
          type="asignTarea"
          title="Asignarme la Tarea"
          message="¿Está seguro de tomar el control de la tarea?"
          labelConfirm="Aceptar"
          confirm={asignTarea}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "massiveAsignTarea" && (
        <Modal
          type="massiveAsignTarea"
          title="Asignarme las Tareas"
          message={"¿Está seguro de tomar el control de las tareas?"}
          labelConfirm="Aceptar"
          summary={summary()}
          confirm={reasignTarea}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "reasignTarea" && (
        <Modal
          type="reasignTarea"
          title="Reasignar Tarea"
          message="Seleccionar el usuario"
          labelConfirm="Reasignar"
          values={lists.Usuario?.valores || []}
          confirm={reasignTarea}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "reabrirTarea" && (
        <Modal
          type="reabrirTarea"
          title="Reabrir la Tarea"
          message="¿Está seguro de reabrir la tarea?"
          labelConfirm="Aceptar"
          confirm={reabrirTarea}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "massiveReasignTarea" && (
        <Modal
          type="massiveReasignTarea"
          title="Reasignación Masiva de Tareas"
          message="Seleccionar el usuario"
          labelConfirm="Reasignar"
          values={lists.Usuario?.valores || []}
          summary={summary()}
          confirm={reasignTarea}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "saveFilter" && (
        <Modal
          type="saveFilter"
          title="Guardar Filtro"
          message="Indique el nombre del nuevo filtro"
          labelConfirm="Guardar"
          confirm={saveFilter}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "deleteFilter" && (
        <Modal
          type="deleteFilter"
          title="Eliminar Filtro"
          message="¿Seguro que desea eliminar el filtro seleccionado?"
          labelConfirm="Eliminar"
          confirm={deleteFilter}
          cancel={() => setVisibleModal("")}
        />
      )}

      <ModuleSearch
        title="Buscador Tareas: Filtro"
        metadata={METADATA_SEARCH_TAREAS}
        lists={lists}
        submit={searchTareas}
        listFilters={listFilters}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        requestSaveFilter={requestSaveFilter}
        requestDeleteFilter={requestDeleteFilter}
        setCurrentSort={setCurrentSort}
      />

      <ModuleResultTareas
        title="Resultados"
        metadata={METADATA_RESULT_TAREAS}
        data={result}
        formData={formData}
        moreResults={moreTareas}
        currentSort={currentSort}
        sort={sort}
        selectedIds={selectedIds}
        handleChangeSelection={handleChangeSelection}
        selectAll={selectAll}
        deselectAll={deselectAll}
        requestAsignTarea={requestAsignTarea}
        requestReAbrirTarea={requestReAbrirTarea}
        requestReasignTarea={requestReasignTarea}
        setLoading={setLoading}
        access={permissions.BuscadorTareas}
      />
    </S.Wrapper>
  );
};
