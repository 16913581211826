import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Button } from "../../components/Button";
import { InputList } from "../InputList";
import { InputTextArea } from "../InputTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { InputDate } from "../InputDate";

export const ModalTareaReclamacion = ({
  type,
  title,
  labelConfirm,
  numSelected,
  lists,
  confirm,
  cancel,
  documentName,
}) => {
  const [listsProps, setListsProps] = useState({});
  const [values, setValues] = useState({});
  const [filename, setFilename] = useState("");
  const [data64File, setData64File] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [fechaEmision, setFechaEmision] = useState("");

  const initLists = useCallback(() => {
    const tmpListsProps = {};
    Object.entries(lists).forEach(([listName, listProps]) => {
      tmpListsProps[listName] = {
        value: listProps.valorDefecto,
        options: convertOptions([...listProps.valores]),
      };
    });
    setListsProps(tmpListsProps);
  }, [lists]);
  useEffect(() => initLists(), [initLists]);

  const handleChange = (field, value) => {
    const tmpValues = { ...values };
    tmpValues[field] = value;
    setValues(tmpValues);
  };

  const handleListChange = (field, value) => {
    const tmpListsProps = { ...listsProps };
    tmpListsProps[field.list].value = value;
    tmpListsProps[field.list].name = field.name;

    if (lists[field.list].listasHijas.length === 0)
      setListsProps(tmpListsProps);
    else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpListsProps[list].options = convertOptions(res.valores);
          setListsProps(tmpListsProps);
        });
      });
  };

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const convertBase64 = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64 = reader.result.toString().replace(/^data:(.*,)?/, "");
        const nameSplit = file.name.split(".");
        setFilename(file.name);
        setData64File(base64);
        setFileExt(nameSplit[nameSplit.length - 1]);
      };
    } else {
      setFilename("");
      setData64File("");
      setFileExt("");
    }
  };

  const formatoFecha  = (fecha) => {
    return fecha.split("T")[0]
  }


  const body = () => {
    switch (type) {
      case "confirm":
        return (
          <S.WrapperModalBody>
            <h3>¿Está seguro que quiere continuar con la modificación?</h3>
            <h4>
              Se van a realizar modificaciones sobre{" "}
              <span className="num">{numSelected}</span>{" "}
              {numSelected === "1" ? "reclamación" : "reclamaciones"}
            </h4>
          </S.WrapperModalBody>
        );

      default:
        return (
          <S.WrapperModalBody>
            <InputList
              field={{
                label: "Reclamación",
                name: "idEstadoReclamacion",
                list: "EstadosReclamacion",
              }}
              value={listsProps.EstadosReclamacion?.value || ""}
              options={listsProps.EstadosReclamacion?.options || []}
              handleChange={handleListChange}
              isClearable={lists.EstadosReclamacion?.mostrarVacio || false}
            />
            {(type === "Certificado de Comunidades" ||
              type === "Certificado de IBI") && (
              <InputList
                field={{
                  label: "Deuda al día",
                  name: "idDeudaAlDia",
                  list: "DeudaAlDia",
                }}
                value={listsProps.DeudaAlDia?.value || ""}
                options={listsProps.DeudaAlDia?.options || []}
                handleChange={handleListChange}
                isClearable={lists.DeudaAlDia?.mostrarVacio || false}
              />
            )}
            <InputDate
              key={"fechaEmision"}
              field={{
                name: "fechaEmision",
                label: "Fecha de emisión",
                type: "date",
              }}
              value={formatoFecha(fechaEmision)}
              handleChange={(_f, v) => setFechaEmision(v)}
            />
            <InputTextArea
              field={{
                label: "Observaciones",
                name: "observacion",
              }}
              rows={5}
              value={values.observacion}
              handleChange={handleChange}
            />
            <S.InputFile>
              <input type="file" onChange={convertBase64} />
              <FontAwesomeIcon icon={faFileArrowUp} size="2x" />
              <p>{filename || documentName || "Ningún archivo seleccionado"}</p>
            </S.InputFile>
          </S.WrapperModalBody>
        );
    }
  };

  const confirmComplete = () => {
    const data = {};
    Object.entries(values).forEach(([name, value]) => (data[name] = value));
    Object.entries(listsProps).forEach(([list, props]) => {
      data[props.name] = props.value;
    });
    data.contenidobase64 = data64File;
    data.tituloDocumento = filename;
    data.tipoContenido = fileExt;
    confirm(data);
  };

  const buttons = () => {
    switch (type) {
      case "confirm":
        return (
          <>
            <Button label={labelConfirm} onClick={confirmComplete} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "Certificado de Comunidades":
      case "Certificado de IBI":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmComplete}
              disabled={
                !listsProps.EstadosReclamacion?.value ||
                !listsProps.DeudaAlDia?.value
              }
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      default:
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmComplete}
              disabled={!listsProps.EstadosReclamacion?.value}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );
    }
  };

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          {body()}
          <S.Buttons>{buttons()}</S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalTareaReclamacion.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  labelConfirm: PropTypes.string,
  numSelected: PropTypes.string,
  lists: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  documentName: PropTypes.string,
};
