import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleTareaDocsReserva } from "../../components/ModuleTareaDocsReserva";
import { ModuleTareaDocsActivos } from "../../components/ModuleTareaDocsActivos";
import { ModuleFondosReserva } from "../../components/ModuleFondosReserva";
import { ModuleRevisionTareasCancelacion } from "../../components/ModuleRevisionTareasCancelacion";
import { ModuleRevisionTareasTitularidad } from "../../components/ModuleRevisionTareasTitularidad";
import { ModalTareaAnalisis } from "../../components/ModalTareaAnalisis";
import { ModalDocumentos } from "../../components/ModalDocumentos";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_TAREA_ANALISIS_DATOS_GENERALES,
  METADATA_DOCS_RESERVA,
  METADATA_DOCS_ACTIVOS,
  METADATA_FONDO_RESERVA,
  METADATA_REV_CANCELACION,
  METADATA_REV_TITULARIDAD,
  METADATA_AUDITORIA_CAMPOS,
  METADATA_OBSERVACIONES_ANALISIS_INMUEBLE,
} from "../../constants";
import { ModuleAuditoriaCampos } from "../../components/ModuleAuditoriaCampos";
import { ModuleObservaciones } from "../../components/ModuleObservaciones";
import { usePermissions } from "../../context/PermissionsContext";

export const TareaAnalisis = () => {
  const { permissions } = usePermissions();
  const { idTarea } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosAuditoria, setDatosAuditoria] = useState([]);
  const [datosGenerales, setDatosGenerales] = useState({});
  const [docsReserva, setDocsReserva] = useState([]);
  const [docsActivos, setDocsActivos] = useState([]);
  const [revTareasCancelacion, setRevTareasCancelacion] = useState([]);
  const [revTareasTitularidad, setRevTareasTitularidad] = useState([]);
  const [fondosReserva, setFondosReserva] = useState([]);
  const [activo, setActivo] = useState({});
  const [document, setDocument] = useState({});
  const [idCarga, setIdCarga] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    API.abrirTarea({ tarea: idTarea })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setDocsReserva(res.documentosReserva);
          setDocsActivos(res.documentosActivos);
          setRevTareasCancelacion(res.revisionesTareasCancelacion);
          setRevTareasTitularidad(res.revisionesTareasTitularidad);
          setFondosReserva(res.fondos);
          setDatosAuditoria(res.notificacion);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("TareaAnalisis", 0, idTarea)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));
    return () => (isMounted = false);
  }, [idTarea]);

  useEffect(() => {
    if (triggerRefresh) {
      API.abrirTarea({ tarea: idTarea }).then((res) => {
        setDocsReserva(res.documentosReserva);
        setDocsActivos(res.documentosActivos);
        setRevTareasCancelacion(res.revisionesTareasCancelacion);
        setRevTareasTitularidad(res.revisionesTareasTitularidad);
        setFondosReserva(res.fondos);
        setDatosAuditoria(res.notificacion);
      });
      setTriggerRefresh(false);
    }
  }, [idTarea, triggerRefresh]);

  const requestAddReserveDocument = () => {
    setVisibleModal("addReserveDocument");
  };

  const addReserveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;

    API.crearDocumentoReserva(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Documento creado");
      })
      .catch((error) => {
        toast.error("No se pudo crear el documento\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestAddActiveDocument = (activo) => {
    setActivo(activo);
    setVisibleModal("addActiveDocument");
  };

  const requestAddFondoReserva = () => {
    API.obtenerRevisionFondos({ idReserva: datosGenerales.idReserva })
      .then((res) => {
        addListActivosFondos(res);
        setVisibleModal("addFondoReserva");
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const addActiveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;
    data.idReserva = datosGenerales.idReserva;
    data.idActivo = activo.id;

    API.crearDocumentoActivo(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Documento creado");
      })
      .catch((error) => {
        toast.error("No se pudo crear el documento\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestEditDocument = (activo, document) => {
    setActivo(activo);
    setDocument(document);
    setVisibleModal("editActiveDocument");
  };

  const editActiveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;
    data.idReserva = datosGenerales.idReserva;
    data.idActivo = activo.id;
    data.idDocumentoActivo = document.id;

    API.editarDocumentoActivo(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Guardado");
      })
      .catch((error) => {
        toast.error("No se pudo guardar\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestAddCarga = () => {
    setLoading(true);
    API.obtenerActivosCancelacion({ idReserva: datosGenerales.idReserva })
      .then((res) => {
        addListActivosFondos(res);
        setVisibleModal("addNewCarga");
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const requestAddTitularidad = () => {
    setLoading(true);
    API.obtenerActivosTitularidad({ idReserva: datosGenerales.idReserva })
      .then((res) => {
        addListActivosFondos(res);
        setVisibleModal("addNewTitularidad");
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const addListActivosFondos = (res) => {
    const tmpLists = { ...lists };
    tmpLists[res.nombreLista] = res;
    setLists(tmpLists);
  };

  const removeListActivos = (res) => {
    const tmpLists = { ...lists };
    delete tmpLists.Activo;
    setLists(tmpLists);
  };

  const addCarga = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idReserva = datosGenerales.idReserva;
    data.idTarea = idTarea;
    API.nuevaTareaCarga(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Creada exitosamente");
          setTriggerRefresh(true);
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const addFondoReserva = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idReserva = datosGenerales.idReserva;
    data.idTarea = idTarea;
    API.nuevoFondo(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Creado exitosamente");
          setTriggerRefresh(true);
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const requestCancelCarga = (idCarga) => {
    setIdCarga(idCarga);
    setVisibleModal("cancelCarga");
  };

  const cancelCarga = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idCarga = idCarga;
    data.idTarea = idTarea;
    API.cancelarProcesoCarga(data)
      .then((res) => {
        toast.success("Tarea cancelada");
        setTriggerRefresh(true);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const guardarCompletarTareaAnalisis = (completarTarea) => {
    setLoading(true);
    API.guardarCompletarTareaAnalisis({
      idTarea,
      idReserva: datosGenerales.idReserva,
      ObservacionesInmueble: datosGenerales.observacionesInmueble,
      completarTarea,
    })
      .then((res) => {
        if (res.resultadoPeticion === 1 && completarTarea) {
          toast.success("Tarea Completada");
          navigate("/");
        } else if (res.resultadoPeticion === 1 && completarTarea === false) {
          toast.success("Tarea Guardada");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const deleteActiveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");

    data.idTarea = idTarea;
    data.idReserva = datosGenerales.idReserva;
    data.idActivo = activo.id;
    data.idDocumentoActivo = document.id;

    API.eliminarDocumentoActivo(data)

      .then((res) => {
        if (res !== null) {
          toast.success("Documento y tarea correspondiente borrada");
        } else {
          toast.error(`No se pudo borrar el documento\n${res.mensajeUsuario}`);
        }
      })
      .catch((error) => {
        toast.error("No se pudo borrar el documento");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setTriggerRefresh(true);
      });
  };

  const requestBorradoTarea = (activo, document) => {
    setActivo(activo);
    setDocument(document);
    setVisibleModal("confirmarBorradoTarea");
  };

  const ocultarCampo = (idNotificacion, ocultar, eliminar) => {
    setLoading(true);
    const data = {};
    data.IdNotificacion = idNotificacion;
    data.Visibilidad = ocultar;
    data.Eliminado = eliminar;
    API.guardarVisibilidadNotificacionRevOp(data)
      .then((res) => {
        setTriggerRefresh(true);
        if (res.resultadoPeticion === 1) toast.success(res.mensajeUsuario);
        else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "addReserveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoReserva"
            confirm={addReserveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "addActiveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoActivo"
            activo={activo}
            claimable={true}
            confirm={addActiveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "editActiveDocument":
        return (
          <ModalDocumentos
            type="editActiveDocument"
            title="Editar detalles del documento"
            document={document}
            labelConfirm="Guardar"
            lists={lists}
            activo={activo}
            claimable={true}
            confirm={editActiveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "confirmarBorradoTarea":
        return (
          <ModalTareaAnalisis
            type={visibleModal}
            title="Confirmación borrado tarea"
            labelConfirm="Borrar"
            lists={lists}
            confirm={deleteActiveDocument}
            cancel={() => setVisibleModal("")}
          />
        );
      case "addNewCarga":
        return (
          <ModalTareaAnalisis
            type={visibleModal}
            title="Agregar Nueva Carga"
            idreserva={datosGenerales.idReserva}
            labelConfirm="Guardar"
            lists={lists}
            confirm={addCarga}
            cancel={() => {
              setVisibleModal("");
              removeListActivos();
            }}
          />
        );

      case "addNewTitularidad":
        return (
          <ModalTareaAnalisis
            type={visibleModal}
            title="Agregar Nueva Titularidad"
            labelConfirm="Guardar"
            lists={lists}
            confirm={addCarga}
            cancel={() => setVisibleModal("")}
          />
        );
      case "addFondoReserva":
        return (
          <ModalTareaAnalisis
            type={visibleModal}
            title="Agregar Nuevo Fondo"
            labelConfirm="Guardar"
            lists={lists}
            confirm={addFondoReserva}
            cancel={() => setVisibleModal("")}
          />
        );

      case "cancelCarga":
        return (
          <ModalTareaAnalisis
            type={visibleModal}
            title="Cancelación"
            labelConfirm="Sí, continuar"
            lists={{}}
            confirm={cancelCarga}
            cancel={() => setVisibleModal("")}
          />
        );

      default:
        return null;
    }
  };
  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal && renderModal()}

      {datosGenerales.esVentaDigital && (
        <h1 className="highlighted">Venta Digital</h1>
      )}
      {datosGenerales.crmForzadas && (
        <h1 className="highlighted2">Forzada CRM</h1>
      )}

      <ModuleAuditoriaCampos
        data={datosAuditoria}
        metadata={METADATA_AUDITORIA_CAMPOS}
        ocultar={ocultarCampo}
        access={permissions.TareaAnalisis}
      />

      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_TAREA_ANALISIS_DATOS_GENERALES}
      />

      <ModuleTareaDocsReserva
        data={docsReserva}
        metadata={METADATA_DOCS_RESERVA}
        requestAdd={requestAddReserveDocument}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.TareaAnalisis}
      />
      {datosGenerales.esFastrack && <h1 className="highlighted3">Fastrack</h1>}

      <ModuleTareaDocsActivos
        data={docsActivos}
        metadata={METADATA_DOCS_ACTIVOS}
        requestAdd={requestAddActiveDocument}
        requestEdit={requestEditDocument}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.TareaAnalisis}
        requestBorradoTarea={requestBorradoTarea}
      />
      <ModuleObservaciones
        metadata={METADATA_OBSERVACIONES_ANALISIS_INMUEBLE}
        values={datosGenerales}
        setValues={setDatosGenerales}
        access={permissions.RevisionOP}
      />
      <ModuleFondosReserva
        data={fondosReserva}
        metadata={METADATA_FONDO_RESERVA}
        requestAdd={requestAddFondoReserva}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.TareaAnalisis}
        requestBorradoTarea={requestBorradoTarea}
      />

      <S.ModulesRow>
        <ModuleRevisionTareasCancelacion
          data={revTareasCancelacion}
          metadata={METADATA_REV_CANCELACION}
          requestAdd={requestAddCarga}
          requestCancel={requestCancelCarga}
          access={permissions.TareaAnalisis}
        />
        <ModuleRevisionTareasTitularidad
          data={revTareasTitularidad}
          metadata={METADATA_REV_TITULARIDAD}
          requestAdd={requestAddTitularidad}
          requestCancel={requestCancelCarga}
          access={permissions.TareaAnalisis}
        />
      </S.ModulesRow>

      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
        <div>
          <Button
            label="Guardar"
            onClick={() => guardarCompletarTareaAnalisis(false)}
            disabled={!permissions.TareaAnalisis?.Completar}
          />
          <Button
            label="Completar"
            onClick={() => guardarCompletarTareaAnalisis(true)}
            disabled={!permissions?.TareaAnalisis?.Completar}
          />
        </div>
      </S.Buttons>
    </S.Wrapper>
  );
};
